/* eslint-disable react/no-unknown-property */
import { h, ComponentProps } from 'preact';
import classnames from 'classnames';

import { Svg } from './Svg';

type SvgProps = ComponentProps<typeof Svg> & {
    className?: string;
};

export const IconFacebook = ({ className, ...rest }: SvgProps) => (
    <Svg className={classnames('c-icon c-icon--facebook', className)} viewBox="0 0 9 20" {...rest}>
        <path d="M5.842 20v-9.123h2.653l.398-3.555H5.842v-2.27c0-1.03.248-1.731 1.527-1.731H9V.14C8.718.096 7.75 0 6.623 0 4.27 0 2.66 1.657 2.66 4.7v2.622H0v3.555h2.66V20h3.182z" />
    </Svg>
);

export const IconTwitter = ({ className, ...rest }: SvgProps) => (
    <Svg className={classnames('c-icon c-icon--twitter', className)} viewBox="0 0 26 28" {...rest}>
        <path d="M25.312 6.375c-0.688 1-1.547 1.891-2.531 2.609 0.016 0.219 0.016 0.438 0.016 0.656 0 6.672-5.078 14.359-14.359 14.359-2.859 0-5.516-0.828-7.75-2.266 0.406 0.047 0.797 0.063 1.219 0.063 2.359 0 4.531-0.797 6.266-2.156-2.219-0.047-4.078-1.5-4.719-3.5 0.313 0.047 0.625 0.078 0.953 0.078 0.453 0 0.906-0.063 1.328-0.172-2.312-0.469-4.047-2.5-4.047-4.953v-0.063c0.672 0.375 1.453 0.609 2.281 0.641-1.359-0.906-2.25-2.453-2.25-4.203 0-0.938 0.25-1.797 0.688-2.547 2.484 3.062 6.219 5.063 10.406 5.281-0.078-0.375-0.125-0.766-0.125-1.156 0-2.781 2.25-5.047 5.047-5.047 1.453 0 2.766 0.609 3.687 1.594 1.141-0.219 2.234-0.641 3.203-1.219-0.375 1.172-1.172 2.156-2.219 2.781 1.016-0.109 2-0.391 2.906-0.781z" />
    </Svg>
);

export const IconInstagram = ({ className, ...rest }: SvgProps) => (
    <Svg
        className={classnames('c-icon c-icon--instagram', className)}
        viewBox="0 0 24 24"
        {...rest}
    >
        <path d="M7 1c-1.657 0-3.158 0.673-4.243 1.757s-1.757 2.586-1.757 4.243v10c0 1.657 0.673 3.158 1.757 4.243s2.586 1.757 4.243 1.757h10c1.657 0 3.158-0.673 4.243-1.757s1.757-2.586 1.757-4.243v-10c0-1.657-0.673-3.158-1.757-4.243s-2.586-1.757-4.243-1.757zM7 3h10c1.105 0 2.103 0.447 2.828 1.172s1.172 1.723 1.172 2.828v10c0 1.105-0.447 2.103-1.172 2.828s-1.723 1.172-2.828 1.172h-10c-1.105 0-2.103-0.447-2.828-1.172s-1.172-1.723-1.172-2.828v-10c0-1.105 0.447-2.103 1.172-2.828s1.723-1.172 2.828-1.172zM16.989 11.223c-0.15-0.972-0.571-1.857-1.194-2.567-0.754-0.861-1.804-1.465-3.009-1.644-0.464-0.074-0.97-0.077-1.477-0.002-1.366 0.202-2.521 0.941-3.282 1.967s-1.133 2.347-0.93 3.712 0.941 2.521 1.967 3.282 2.347 1.133 3.712 0.93 2.521-0.941 3.282-1.967 1.133-2.347 0.93-3.712zM15.011 11.517c0.122 0.82-0.1 1.609-0.558 2.227s-1.15 1.059-1.969 1.18-1.609-0.1-2.227-0.558-1.059-1.15-1.18-1.969 0.1-1.609 0.558-2.227 1.15-1.059 1.969-1.18c0.313-0.046 0.615-0.042 0.87-0.002 0.74 0.11 1.366 0.47 1.818 0.986 0.375 0.428 0.63 0.963 0.72 1.543zM18.5 6.5c0-0.552-0.448-1-1-1s-1 0.448-1 1 0.448 1 1 1 1-0.448 1-1z" />
    </Svg>
);

export const IconMenu = ({ className, ...rest }: SvgProps) => (
    <Svg className={classnames('c-icon c-icon--menu', className)} viewBox="0 0 24 24" {...rest}>
        <path d="M4.5 5.5H19.5" stroke-width="3" stroke-linecap="square" />
        <path d="M4.5 12.5H19.5" stroke-width="3" stroke-linecap="square" />
        <path d="M4.5 19.5H19.5" stroke-width="3" stroke-linecap="square" />
    </Svg>
);

export const IconClose = ({ className, ...rest }: SvgProps) => (
    <Svg className={classnames('c-icon c-icon--close', className)} viewBox="0 0 24 24" {...rest}>
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M5 6l13.065 12.653M5 18.653L18.065 6"
        />
    </Svg>
);

export const IconCaretLeft = ({ className, ...rest }: SvgProps) => (
    <Svg
        className={classnames('c-icon c-icon--caret-left', className)}
        viewBox="0 0 36 36"
        {...rest}
    >
        <path
            fill="none"
            d="M20 27L11 18.5L20 10"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </Svg>
);

export const IconCaretRight = ({ className, ...rest }: SvgProps) => (
    <Svg
        className={classnames('c-icon c-icon--caret-right', className)}
        viewBox="0 0 36 36"
        {...rest}
    >
        <path
            fill="none"
            d="M14 10L23 18.5L14 27"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </Svg>
);

export const IconCaretDown = ({ className, ...rest }: SvgProps) => (
    <Svg
        className={classnames('c-icon c-icon--caret-down', className)}
        viewBox="0 0 24 24"
        {...rest}
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.88 9.29006L12 13.1701L8.12 9.29006C7.73 8.90006 7.1 8.90006 6.71 9.29006C6.32 9.68006 6.32 10.3101 6.71 10.7001L11.3 15.2901C11.69 15.6801 12.32 15.6801 12.71 15.2901L17.3 10.7001C17.69 10.3101 17.69 9.68006 17.3 9.29006C16.91 8.91006 16.27 8.90006 15.88 9.29006Z"
        />
    </Svg>
);

export const IconArrowRight = ({ className, ...rest }: SvgProps) => (
    <Svg
        className={classnames('c-icon c-icon--arrow-right', className)}
        viewBox="0 0 18 15"
        {...rest}
    >
        <path d="M1.00031 6.5C0.44802 6.5 0.000305176 6.94772 0.000305176 7.5C0.000305176 8.05228 0.44802 8.5 1.00031 8.5V6.5ZM17.7074 8.20711C18.0979 7.81658 18.0979 7.18342 17.7074 6.79289L11.3435 0.428932C10.9529 0.0384078 10.3198 0.0384078 9.92924 0.428932C9.53871 0.819457 9.53871 1.45262 9.92924 1.84315L15.5861 7.5L9.92924 13.1569C9.53871 13.5474 9.53871 14.1805 9.92924 14.5711C10.3198 14.9616 10.9529 14.9616 11.3435 14.5711L17.7074 8.20711ZM1.00031 8.5H17.0003V6.5H1.00031V8.5Z" />
    </Svg>
);

export const IconPlay = ({ className, ...rest }: SvgProps) => (
    <Svg className={classnames('c-icon c-icon--play', className)} viewBox="0 0 24 25" {...rest}>
        <path
            fill-rule="evenodd"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 23.4V1l14 11.2L7 23.4Z"
            clip-rule="evenodd"
        />
    </Svg>
);

export const IconHelpBubble = (props: SvgProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 39 40" {...props}>
        <rect width="38" height="38" x=".5" y=".5" fill="#F9F9F9" rx="19" />
        <path
            fill="#F9F9F9"
            fill-rule="evenodd"
            stroke="#F9F9F9"
            stroke-linejoin="round"
            stroke-width="2"
            d="M26 35l8.218 4-1.298-9.521L26 35z"
            clip-rule="evenodd"
        />
        <path
            stroke="none"
            fill="#6E66DB"
            d="M17.56 22h3.84c0-1.656 3.84-2.76 3.84-6.336 0-3.12-2.736-4.8-5.472-4.8-2.664 0-4.992 1.392-6.048 3.864l3.312 1.896c.48-1.128 1.272-2.016 2.592-2.016 1.224 0 1.776.552 1.776 1.392 0 2.112-3.84 3.192-3.84 6zm1.92 6.336a2.295 2.295 0 002.28-2.28 2.295 2.295 0 00-2.28-2.28 2.295 2.295 0 00-2.28 2.28 2.295 2.295 0 002.28 2.28z"
        />
    </svg>
);

export const IconCalendar = (props: SvgProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 38 38" {...props}>
        <ellipse cx="19.258" cy="18.916" fill="#fff" rx="18.451" ry="18.5" />
        <rect
            width="19.3"
            height="21.195"
            x="9.745"
            y="8.755"
            fill="#fff"
            stroke="#434254"
            stroke-width="2"
            rx="3.381"
        />
        <path
            fill="#434254"
            fill-rule="evenodd"
            d="M9.745 12.136a3.381 3.381 0 0 1 3.381-3.381h12.537a3.381 3.381 0 0 1 3.382 3.381v1.457h-19.3v-1.457Z"
            clip-rule="evenodd"
        />
        <rect width="1.843" height="1.843" x="14.678" y="16.588" fill="#434254" rx=".922" />
        <rect width="1.843" height="1.843" x="18.473" y="16.588" fill="#434254" rx=".922" />
        <rect width="1.843" height="1.843" x="22.269" y="16.588" fill="#434254" rx=".922" />
        <rect width="1.843" height="1.843" x="14.678" y="20.273" fill="#434254" rx=".922" />
        <rect width="1.843" height="1.843" x="18.473" y="20.273" fill="#434254" rx=".922" />
        <rect width="1.843" height="1.843" x="22.269" y="20.273" fill="#434254" rx=".922" />
        <rect width="1.843" height="1.843" x="14.678" y="23.96" fill="#434254" rx=".922" />
        <rect width="1.843" height="1.843" x="18.473" y="23.96" fill="#434254" rx=".922" />
        <rect width="1.843" height="1.843" x="22.269" y="23.96" fill="#434254" rx=".922" />
        <rect width="3" height="6" x="13.69" y="4.49" fill="#434254" stroke="#fff" rx="1.5" />
        <rect width="3" height="6" x="22.145" y="4.49" fill="#434254" stroke="#fff" rx="1.5" />
    </svg>
);

export const IconTickYes = ({ className, ...rest }: SvgProps) => (
    <Svg className={classnames('c-icon c-icon--yes', className)} viewBox="0 0 23 23" {...rest}>
        <path
            fill-rule="evenodd"
            d="M11.675.282c5.961 0 10.794 4.833 10.794 10.794S17.636 21.87 11.675 21.87.881 17.037.881 11.076 5.714.282 11.675.282Z"
            clip-rule="evenodd"
        />
        <path
            fill="none"
            stroke="#6E66DB"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="m6.614 10.728 3.844 4.007 5.935-6"
        />
    </Svg>
);

export const IconTickNo = ({ className, ...rest }: SvgProps) => (
    <Svg className={classnames('c-icon c-icon--no', className)} viewBox="0 0 22 23" {...rest}>
        <path
            fill-rule="evenodd"
            d="M10.881.488c5.961 0 10.794 4.833 10.794 10.794S16.842 22.076 10.88 22.076.087 17.243.087 11.282 4.92.488 10.881.488Z"
            clip-rule="evenodd"
        />
        <path
            fill="none"
            stroke="#434254"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="m7.331 14.838 7.035-7.112M14.367 14.838 7.33 7.726"
        />
    </Svg>
);

export const IconFlex = ({ className, ...rest }: SvgProps) => (
    <Svg className={classnames(className)} viewBox="0 0 30 17" fill="none" {...rest}>
        <path
            stroke="url(#a)"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.797"
            d="m6.688 10.11-.25 5.076-4.913-.208"
        />
        <path
            stroke="url(#b)"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.837"
            d="m22.309 7.143.8-5.018 4.86.744"
        />
        <path
            stroke="url(#c)"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3.197"
            d="M3.892 12.43a5.142 5.142 0 0 1 3.639-8.777c1.699 0 3.14.883 4.247 2.098l2.886 3.089 3.06 3.276c1.235 1.314 2.416 1.82 3.94 1.82a5.142 5.142 0 0 0 3.711-8.7"
        />
        <circle cx="24.343" cy="3.665" r="1.099" fill="url(#d)" />
        <circle cx="4.812" cy="13.655" r="1.099" fill="url(#e)" />
        <defs>
            <linearGradient
                id="a"
                x1="1.218"
                x2="10.81"
                y1="8.779"
                y2="9.174"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#6E66DB" />
                <stop offset="1" stop-color="#ED56A1" />
            </linearGradient>
            <linearGradient
                id="b"
                x1="21.952"
                x2="32.295"
                y1=".438"
                y2=".971"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#6E66DB" />
                <stop offset="1" stop-color="#ED56A1" />
            </linearGradient>
            <linearGradient
                id="c"
                x1="3.561"
                x2="42.62"
                y1="-.09"
                y2="11.156"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#6E66DB" />
                <stop offset="1" stop-color="#ED56A1" />
            </linearGradient>
            <linearGradient
                id="d"
                x1="23.35"
                x2="27.102"
                y1="1.766"
                y2="2.221"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#6E66DB" />
                <stop offset="1" stop-color="#ED56A1" />
            </linearGradient>
            <linearGradient
                id="e"
                x1="3.819"
                x2="7.57"
                y1="11.756"
                y2="12.211"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#6E66DB" />
                <stop offset="1" stop-color="#ED56A1" />
            </linearGradient>
        </defs>
    </Svg>
);

export const IconUnlimited = ({ className, ...rest }: SvgProps) => (
    <Svg className={classnames(className)} viewBox="0 0 29 15" fill="none" {...rest}>
        <path
            stroke="url(#aa)"
            stroke-miterlimit="10"
            stroke-width="3.197"
            d="M11.488 10.975a5.64 5.64 0 0 1-4.12 1.806 5.145 5.145 0 0 1 0-10.287c1.699 0 3.14.884 4.247 2.101l5.946 6.365c1.235 1.314 2.416 1.821 3.939 1.821a5.146 5.146 0 1 0 0-10.287 5.445 5.445 0 0 0-4.032 1.928l-5.98 6.553Z"
        />
        <defs>
            <linearGradient id="aa">
                <stop stop-color="#6E66DB" />
                <stop offset="1" stop-color="#ED56A1" />
            </linearGradient>
        </defs>
    </Svg>
);
