import root from 'window-or-global';

// https://gist.github.com/paullewis/55efe5d6f05434a96c36
const rICShim = (callback) => {
    const start = Date.now();
    return setTimeout(() => {
        callback({
            didTimeout: false,
            timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
        });
    }, 1);
};

const requestIdleCallback = root.requestIdleCallback || rICShim;

const loadAnalytics = () => {
    if (typeof window === 'undefined') {
        return;
    }

    if (process.env.HUGO_LOGROCKET) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const LogRocket = require('logrocket');
        // Specifing rootHostname enables tracking session accross subdomains (ie. from www to app).
        LogRocket.init(process.env.HUGO_LOGROCKET, {
            rootHostname: 'withhugo.com',
        });
    }

    if (process.env.HUGO_SEGMENT) {
        window.analytics.load &&
            window.analytics.load(process.env.HUGO_SEGMENT, {
                integrations: {
                    'Visual Tagger': false,
                },
            });
    }
};

export const runAnalytics = () => {
    requestIdleCallback(loadAnalytics, { timeout: 1000 });
};
