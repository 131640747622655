import { h, FunctionComponent } from 'preact';
import classnames from 'classnames';

interface IButtonProps extends h.JSX.DOMAttributes<HTMLButtonElement & HTMLAnchorElement> {
    block?: boolean;
    className?: string;
    disabled?: boolean;
    href?: string;
    icon?: boolean;
    inverted?: boolean;
    large?: boolean;
    link?: boolean;
    loading?: boolean;
    minimal?: boolean;
    outline?: boolean;
    primary?: boolean;
    secondary?: boolean;
    raised?: boolean;
    type?: 'button' | 'submit' | 'reset';
    rel?: string;
    target?: '_blank';
}

export const Button: FunctionComponent<IButtonProps> = ({
    block,
    children,
    className = '',
    href,
    icon,
    inverted,
    large,
    link,
    loading,
    minimal,
    outline,
    primary,
    raised,
    secondary,
    type,
    ...rest
}) => {
    const Element = href ? 'a' : 'button';
    return (
        <Element
            className={classnames(className, {
                'c-link': link,
                'c-link--inverted': inverted && link,
                'c-spinner': loading,
                'c-btn': !link && !className.includes('c-link'),
                'c-btn--block': block || large,
                'c-btn--icon': icon,
                'c-btn--inverted': inverted && !link && !className.includes('c-link'),
                'c-btn--large': large,
                'c-btn--outline': outline,
                'c-btn--primary': primary,
                'c-btn--raised': raised,
                'c-btn--secondary': secondary,
                'c-btn--outline u-shadow-none': minimal,
            })}
            type={href ? undefined : type}
            href={href}
            {...rest}
        >
            {children}
        </Element>
    );
};
