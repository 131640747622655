import { h, FunctionalComponent, ComponentProps } from 'preact';
import { route } from 'preact-router';
import classnames from 'classnames';

import { IconArrowRight } from '../icons';

import { Button } from './Button';

type Props = ComponentProps<typeof Button> & {
    header?: boolean;
    onClick?: () => void;
};

export const ButtonCta: FunctionalComponent<Props> = ({
    children,
    className,
    header,
    icon,
    ...props
}) => {
    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        if (props.onClick && !props.href) {
            return props.onClick();
        }

        if (props.href && !props.href.startsWith('http')) {
            return route(props.href);
        }

        // Pass query string params from LP to the app.
        window.location.assign(`${props.href}${window.location.search}`);
    };

    return (
        <Button
            className={classnames({ 'c-btn--cta': !header }, className)}
            large={!header}
            onClick={handleClick}
            primary
            raised={!header}
            icon={icon}
            {...props}
        >
            <span>{children}</span>
            {icon && <IconArrowRight className="u-font-3" />}
        </Button>
    );
};
