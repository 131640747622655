// Common media queries ported from CSS to be used with useMediaQuery hook
// See: styles/01-abstracts/_variables.scss#BREAKPOINTS

export default {
    MIN_SMALL: '(min-width: 600px)',
    MIN_MEDIUM: '(min-width: 900px)',
    MIN_LARGE: '(min-width: 1200px)',

    MAX_SMALL: '(max-width: 599px)',
    MAX_MEDIUM: '(max-width: 899px)',
    MAX_LARGE: '(max-width: 1199px)',
};
