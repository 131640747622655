// NOTE: if updating routes, make sure links.js and prerender-urls.json is up to date as well.

export default {
    ABOUT: '/about',
    CAREERS: '/careers',
    ERROR: '/error',
    HEX: '/hex',
    HOMEPAGE: '/',
    JOIN: '/join',
    LEGAL: '/legal',
    LICENSES: '/licenses',
    LIENHOLDER_SUPPORT: '/lienholder-support',
    OLD_PRIVACY_POLICY: '/old-privacy-policy',
    PAY: '/pay',
    PLANS: '/plans',
    PRIVACY_FORM: '/privacy-form',
    REVIEWS: '/reviews',
};
