import { h, ComponentProps } from 'preact';
import classnames from 'classnames';

import { IconClose, IconMenu } from '../icons';

import { Button } from './Button';

type Props = ComponentProps<typeof Button> & {
    open: boolean;
};

export const MenuButton = ({ open, className, ...props }: Props) => (
    <Button
        icon
        minimal
        className={classnames('c-btn--menu', className, {
            'is-open': open,
        })}
        {...props}
    >
        {open ? <IconClose /> : <IconMenu />}
    </Button>
);
