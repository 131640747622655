import { FunctionComponent, h, ComponentProps } from 'preact';
import { useId } from 'preact/hooks';
import classnames from 'classnames';

type Props = Omit<ComponentProps<'svg'>, 'default'> & {
    title?: string;
    use?: string;
};

export const Svg: FunctionComponent<Props> = ({
    children,
    className,
    height,
    size,
    title,
    use,
    width,
    ...rest
}) => {
    const id = useId();
    return (
        <svg
            aria-hidden={title ? undefined : true}
            aria-labelledby={title ? id : undefined}
            className={classnames(className as string, use)}
            role={title ? 'img' : undefined}
            xmlns="http://www.w3.org/2000/svg"
            width={width || size}
            height={height || size}
            {...rest}
        >
            {title && <title id={id}>{title}</title>}
            {use ? <use href={`#${use}`} /> : children}
        </svg>
    );
};
