// API paths extracted in variables.
const API_URL = process.env.HUGO_API_URL || process.env.NEXT_PUBLIC_API_URL || ''; // No trailing slash.

export default {
    /**
     * Creates a new ticket
     * @api {POST}
     * @returns {Array} ticketTypes
     */
    CREATE_TICKET: `${API_URL}/support/ticket`,

    POLICY_STATUS_LOOKUP: `${API_URL}/support/policy-status`,
};
