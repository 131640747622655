// error-codes.js
// Error codes returned from API or thrown inside client.
// NOTE: Not full list, just ones used for client mapping.

export default {
    // Generic codes, not from API but assigned on response.

    NO_ERROR: 'NO_ERROR',
    INVALID_RECAPTCHA_ERROR: 'InvalidRecaptchaError',
};
