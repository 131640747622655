import { h, FunctionalComponent } from 'preact';

import { links } from '../../constants';
import { HelpButton } from '../button';

import { Header } from './header';
import { Footer } from './footer';

export const Layout: FunctionalComponent = ({ children }) => (
    <div id="root">
        <Header />
        <main>{children}</main>
        <Footer />
        <div className="l-btn-helpdesk">
            <HelpButton className="track:ticket-open" href={links.HELPDESK} size={35} />
        </div>
    </div>
);
